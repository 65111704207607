import React,{useEffect} from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import * as contents from "../../../constants";
import { getVouchersData } from '../../../actions';

  
import RewardBlock from "../../RewardBlock"
const step2 = (props) => {
  const {voucherCredits} = props;
  useEffect(()=>{
      props.getVouchersData(props.user.user)
  },[]);

  const voucerAddress = [contents.APE_WEEK_HAND_CREDIT,contents.APE_USED_RUGS_CREDIT,contents.APE_WORTHLESS_BANANA_CREDIT,contents.APE_UNIQUE_RUG_CREDIT,contents.APE_VALENTINE_CREDIT,contents.APE_MONKEY_HEAD_CREDIT,contents.APE_CHOCOLATE_CREDIT ,contents.PGFKSTAG_CREDIT];
  
  const voucerData = voucerAddress.map((v,i)=>{
    if(voucherCredits[v]){ 
      let v_id = v.split("_")[0];
     return (<div className="col-md-6 mt-5" key={i}>
     <RewardBlock voucher={voucherCredits[v]} vId={v_id}  contractAddress={contents.APE_CREDIT_TOKEN}/>
     </div>)
    }else{
      return null;
    }
  })

  return (
    <div className="row">
      <h2 className="text-center gallery_title my-3">
     <img src="/assets/images/crown.png"/> Ape In NFTs</h2>
         <p style={{fontWeight:"bold",padding:"50px",fontSize:"1.2em"}}>
         If you've joined us for previous seasons you know that we previously launched additional Ape Island Experience NFTs. On Avalanche, we are still working to find all our bananas, rugs, etc. from our trip. Lost in transit they keep telling us. So until we find them, 100 free credits every 24 hours on us!
         </p>
    </div>
  );
};

// @TODO future update review
const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
  voucherCredits: (state) => state.voucherCredits,
});
const mapDisactcProps = {getVouchersData};
export default connect(structuredSelector, mapDisactcProps)(step2);

