import React,{useEffect,useState} from "react"
import { Link } from 'react-router-dom';

const Details = (props) =>{
  const {mintIds,mintStarted,migrationToken,currentSeason} = props;
  const [isOpened, setIsOpened] = useState(true);

  // const [tokenInfo,setTokenInfo] = useState({name:""});
  const myRef = React.createRef();

  const getTokenInfo = ()=>{
    let tokenInfo = migrationToken.find(v=> v.id == mintIds[0]);
    return tokenInfo;
  }

  let part_name = (name) =>{
    switch(name){
      case 'chadium12': return 'Chadium12';
      default: return name;
    }
  }

  let getAttrVal = (key)=>{
    let obj = getTokenInfo().attributes.find((v)=>v.trait_type == key)
    if(obj){
      return part_name(obj.value)
    }else{
      return "N/A"
    }
  }

  let countStar = (key)=>{
    let value = getAttrVal(key);
    let obj = getTokenInfo().attributes.filter((v)=>v.value == value)
    return obj.length;
  }

  const printStar = (count)=>{
    var stars = [];
    for (var i = 0; i < count; i++) {
        // note: we are adding a key prop here to allow react to uniquely identify each
        // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
        stars.push(<img
          src="/assets/images/prize-star-icon.png"
          className="img-fluid"
          key={i}
          width="18"
        />);
    }
    return stars;
  }


  // useEffect(()=>{
  //   console.log("props",props);
  //   console.log("migrationToken",migrationToken);
   
  //   console.log("tInfo",tInfo);
  //   setTokenInfo(tInfo);
  // },[mintIds])

  function toggleStats() {
    document.getElementById('flip_area').style.display='none';
    setIsOpened(wasOpened => !wasOpened);
    if (myRef.current.style.transform == 'rotateY(180deg)'){
      myRef.current.style.transform = 'rotateY(0deg)';
      setTimeout(()=>{
        document.getElementById('body_parts').style.display='flex';
        document.getElementById('flip_area').style.display='block';
      },600)
    } else {
      myRef.current.style.transform = 'rotateY(180deg)';
      setTimeout(()=>{
        document.getElementById('body_parts').style.display='none';
        document.getElementById('flip_area').style.display='block';
      },600)
     
    }
    
  }

  const seasonName=()=>{
    switch(currentSeason){
      case 'season1': return "Season 1";
      case 'season2': return "Season 2";
      case 'season3': return "Season 3";
      case 'offspring': return "Offspring";
    }
  }

  const checkForNA=(value)=>{
    if(mintIds.length > 1){
      return "N/A";
    }else{
      return value;
    }
    
  }

  return(
    <> 
    {mintIds.length == 0 ?
        <div className="apeMakerBuiltMain">
            <div className="apeFlipBox text-center" style={{marginTop:"200px"}}>
             <h3> Please select an Ape to mint.</h3>
          </div>
       </div>
    :
    <div className="apeMakerBuiltMain">
         
            <div className="apeFlipBox" ref={myRef}>
              <div className="apeGeneralView">
                <div className="text-center"><br /><br />
                  <h4 className="mg-h4 mb-0">{mintIds.length} Selected</h4>
                  {mintIds.length == 1 ?
                    <img src={getTokenInfo().image} className="img-fluid" style={{maxWidth: '475px'}} />
                  :
                  <div class="mainRunner">
                    { mintIds.map((id,key)=>{
                       if(key < 5){ 
                      let left = key*1;
                      let t = migrationToken.find(v=> v.id == id);
                      return (<img src="/assets/placeholder-ape.png" title={key} className="img-fluid img_ape" style={{maxWidth: '475px',left:`${left-5}rem`}} />)
                       }else{
                         return null;
                       }
                      })}
                  </div>
                  }
                  
                </div>
                <div className="bodyDetailPart">
                  <div className="wooden_cloud_button text-center" style={{cursor:"pointer",margin:"auto"}}>
                    {mintStarted ?
                        <Link to="#" >
                          <div className="text-center font-22" style={{marginTop:"-2px", lineHeight: '1.25'}}>Wait..</div>
                        </Link>
                      :
                      <Link to="#" onClick={props.mintToken}>
                        <div className="text-center font-22" style={{marginTop:"-2px", lineHeight: '1.25'}}>Mint</div>
                      </Link>
                    }
                   
                  </div>

                  <div className="mx-auto w-75 mt-3"><p style={{fontSize: '14px'}}><b>Note:</b> You will need to approve the transaction before claiming. Also, have a small amount of $AVAX in your wallet for gas.</p></div>
                  <div className="row justify-content-center mt-3" id="body_parts">
                    <div className="col-md-3 pt-2">
                      <div className="maker_part">
                        <h4 className="part_top_title">HEAD</h4>
                        <div className="pt-2">
                          {mintIds.length == 1 ? printStar(countStar('Head')) : <br/>}
                          </div>
                        <div className="empty_space_bg"><label className="circle_lable pt-0">{checkForNA(getTokenInfo().attributes.find(v=>v.trait_type =='Head').value)}</label></div>
                      </div>
                    </div>
                    <div className="col-md-3 pt-2">
                      <div className="maker_part">
                        <h4 className="part_top_title">LEFT ARM</h4>
                        <div className="pt-2">
                        {mintIds.length == 1 ? printStar(countStar('Left Arm')) : <br/>}
                          </div>
                        <div className="empty_space_bg"><label className="circle_lable pt-0">{checkForNA(getTokenInfo().attributes.find(v=>v.trait_type =='Left Arm').value)}</label></div>
                      </div>
                    </div>
                    <div className="col-md-3 pt-2">
                      <div className="maker_part">
                        <h4 className="part_top_title">RIGHT ARM</h4>
                        <div className="pt-2">
                          {mintIds.length == 1 ? printStar(countStar('Right Arm')) : <br/>}
                        </div>
                        <div className="empty_space_bg"><label className="circle_lable pt-0">{checkForNA(getTokenInfo().attributes.find(v=>v.trait_type =='Right Arm').value)}</label></div>
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="col-md-3 pt-2">
                      <div className="maker_part">
                        <h4 className="part_top_title">TORSO</h4>
                        <div className="pt-2">
                        {mintIds.length == 1 ? printStar(countStar('Torso')) : <br/>}
                          </div>
                        <div className="empty_space_bg"><label className="circle_lable pt-0">{checkForNA(getTokenInfo().attributes.find(v=>v.trait_type =='Torso').value)}</label></div>
                      </div>
                    </div>
                    <div className="col-md-3 pt-2">
                      <div className="maker_part">
                        <h4 className="part_top_title">LEFT LEG</h4>
                        <div className="pt-2">
                        {mintIds.length == 1 ? printStar(countStar('Left Leg')) : <br/>}
                          </div>
                        <div className="empty_space_bg"><label className="circle_lable pt-0">{checkForNA(getTokenInfo().attributes.find(v=>v.trait_type =='Left Leg').value)}</label></div>
                      </div>
                    </div>
                    <div className="col-md-3 pt-2">
                      <div className="maker_part">
                        <h4 className="part_top_title">RIGHT LEG</h4>
                        <div className="pt-2">
                        {mintIds.length == 1 ? printStar(countStar('Right Leg')) : <br/>}
                          </div>
                        <div className="empty_space_bg"><label className="circle_lable pt-0">{checkForNA(getTokenInfo().attributes.find(v=>v.trait_type =='Right Leg').value)}</label></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-3">
                  <div className="generate_green_sticker pt-3">
                    <div className="text-center">
                      <div className="count_box" style={{fontSize: '14px'}}>{seasonName()}</div>
                      <h4 className="heading-2">{checkForNA(getTokenInfo().name)}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="apeDetailedView">
                <div className="app_maker_detailed_stats">
                  <ul>
                    <li>
                      <div className="item_details"><i className="fas fa-sword" /> Attact <label>{getTokenInfo().attributes.find(v=>v.trait_type =='Attack').value}</label></div>
                    </li>
                    <li>
                      <div className="item_details"><i className="fas fa-shield" /> Defense <label>{getTokenInfo().attributes.find(v=>v.trait_type =='Defense').value}</label></div>
                    </li>
                    <li>
                      <div className="item_details"><i className="fas fa-bolt" /> Speed <label>{getTokenInfo().attributes.find(v=>v.trait_type =='Speed').value}</label></div>
                    </li>
                    <li>
                      <div className="item_details"><i className="fas fa-magic" /> Intellect <label>{getTokenInfo().attributes.find(v=>v.trait_type =='Intellect').value}</label></div>
                    </li>
                    <li>
                      <div className="item_details"><i className="fas fa-magic" /> Ability <label>{getTokenInfo().attributes.find(v=>v.trait_type =='Ability').value}</label></div>
                    </li>
                    <li>
                      <div className="item_details"><i className="fas fa-monkey" /> Nature <label>{getTokenInfo().attributes.find(v=>v.trait_type =='Nature').value}</label></div>
                    </li>
                    <li style={{height: '55px'}} />
                    <li style={{height: '55px'}} />
                  </ul>
                  <div className="ape_meter animate__animated animate__pulse animate__repeat-2 w-100 text-center" style={{position: 'absolute', bottom: '-0.5rem'}}><img src="/assets/images/rarity_rare.png" width={150} /></div>
                </div>
              </div>
            </div>
     </div>
    }
   </>
    
  )
}


export default Details