import React, { useEffect,useState } from "react";
import {Link} from 'react-router-dom';

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getApeNFTByUser,getApeNFTS} from "../../actions";

import ImageBox from "./ImageBox";

import * as constants from "../../constants";
 

const GalleryList = (props) => {
  const { user, setApe,setApeAddress,currentAddress } = props;
  let { apeNfts,allApeNfts } = props;
  const [showAll,setShowAll] = useState(false);
  const [currentSeason,setCurrentSeason] = useState('season2');
  const myRef = React.createRef();


 if(typeof apeNfts[currentAddress] == "undefined"){
  apeNfts[currentAddress]={type:"",data:[]}
 }
 if(typeof allApeNfts[currentAddress] == "undefined"){
  allApeNfts[currentAddress]={type:"",data:[]}
 }


  let interval = null;

  useEffect(() => {
    if(user.user){
    //  props.getApeNFTByUser(user.user,currentAddress);
     showTokens(showAll);
    }
  }, [currentAddress]);

  useEffect(() => {
    if(myRef.current!=null){ 
      let scrolling = false;
      interval =setInterval(() => {
            if (scrolling) {
                scrolling = false;
                loadMore();
            }
        },300)
        myRef.current.addEventListener('scroll', (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= (e.target.clientHeight+100);
          if(bottom){
            scrolling = true;
          }
      })
    }
    return () => {  
      if(interval!=null){
       clearInterval(interval);
      }
      if(myRef.current!=null){ 
        myRef.current.removeEventListener('scroll');
      }
    }

  }, myRef.current);


  const viewTokens = ()=>{
    showTokens(!showAll);
    setShowAll(!showAll);
  }

  const showTokens = (showData)=>{
      setApe(false);
      if(!showData){
        if(apeNfts[currentAddress].type != 'new_request' || apeNfts[currentAddress].type != 'request'){
          props.getApeNFTByUser(user.user,currentAddress);
        }
      }else{
        if(allApeNfts[currentAddress].type != 'new_request' || allApeNfts[currentAddress].type != 'request'){
          props.getApeNFTS(currentAddress);
        }
      }
  }
  
  const loadMore = ()=>{
    if(showAll && allApeNfts[currentAddress].type =='success'){
      if((parseInt(allApeNfts[currentAddress].totalToken) - allApeNfts[currentAddress].dataPulled) > 0){ 
        props.getApeNFTS(currentAddress,allApeNfts[currentAddress].dataPulled);
      }
    }
    
  }

 let appGallery =   apeNfts[currentAddress]; 
 let allAppGallery =   allApeNfts[currentAddress]; 

//  if(typeof appGallery == "undefined"){
//   appGallery={data:[]}
//  }
//  if(typeof allAppGallery == "undefined"){
//   allAppGallery={data:[]}
//  }

  let galleryImages = appGallery.data.map((v) => {
    return (
     <>
      <div className="col-md-6 mt-3" key={v.id}>
        <ImageBox ape={v} setApe={setApe}/>
      </div>
     </>
    );
  });


  let allGalleryImages = allAppGallery.data.map((v) => {
    return (
     <>
      <div className="col-md-6 mt-3" key={v.id}>
        <ImageBox ape={v} setApe={setApe}/>
      </div>
     </>
    );
  });


  let changeSeason =(e)=>{
    switch(e.target.value){
      case 'season1': setApeAddress(constants.APE_TOKEN_SEASON1_ADDR);props.setTwitterShare(true);break;
      case 'eth_season1': setApeAddress(constants.APE_ETH_TOKEN_SEASON1_ADDR);props.setTwitterShare(true);break;
      case 'eth_season2': setApeAddress(constants.APE_ETH_TOKEN_SEASON2_ADDR);props.setTwitterShare(true);break;
      case 'eth_season3': setApeAddress(constants.APE_ETH_TOKEN_SEASON3_ADDR);props.setTwitterShare(true);break;
      case 'eth_offspring': setApeAddress(constants.APE_ETH_TOKEN_OFFSPRING_ADDR);props.setTwitterShare(true);break;
    }
    setCurrentSeason(e.target.value);
  }

  let finalApeShow = showAll ? allGalleryImages : galleryImages ;
  let mainApeObject = showAll ? allAppGallery : appGallery ;
  
 
  return (
    <>
      <h2 className="text-center gallery_title my-3">
        <img src="/assets/images/round-warning.png" /> &nbsp;{showAll ? "Global Ape Gallery" :"Your Ape Gallery"} 
      </h2>
      <br/>
      <div className="text-center">
        <div className="overlay_select" >Selected: <select value={currentSeason}  onChange={(e)=>{changeSeason(e)}}>
          <option value="season1">Season 1</option>
          <option value="eth_season1">OG Season 1</option>
          <option value="eth_season2">OG Season 2</option>
          <option value="eth_season3">OG Season 3</option>
          <option value="eth_offspring">OG Offspring</option>
          </select></div>
        <br/>
        </div>

      {(mainApeObject.type == 'success' || mainApeObject.type == 'request')?
        <div className="row gallery_data" ref={myRef}>
        {finalApeShow.length > 0 ? 
        <>{finalApeShow}</>
        :
        <>
        {constants.APE_BREED_ADDR == currentAddress ? 
         <div className="col-md-12 text-center"><br/><br/><br/><h2>No Ape Found!</h2>
        </div>
        :
        <div className="col-md-12 text-center"><br/><br/><br/><h2>Arg! Looks like you couldn't find any crew mates for {constants.APE_TOKEN_SEASON1_ADDR == currentAddress ? "Season 1" : "Season 2"}. </h2>
          <br/>
        </div>
          }
        </>
        }
       </div>
      :
      <div className="col-md-12 text-center">
        <br/><br/><br/><h2>Loading apes...</h2>
        <br/><br/>
      </div>
      }


    {(mainApeObject.type == 'success'  || mainApeObject.type == 'request' )&&
      <div className="text-center"> 
        <br/>
        <button className="overlay_btn align-center" onClick={()=>{viewTokens()}} >{showAll ? "View My Apes" :"View All Apes"} </button>
      </div>
    } 
          
    </>
  );
};

// @TODO future update review
const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
  apeNfts: (state) => state.apeNfts,
  allApeNfts: (state) => state.allApeNfts,
  apes: (state) => state.apes,
  allApes: (state) => state.allApes,
  s1Apes: (state) => state.s1Apes,
  s1AllApes: (state) => state.s1AllApes,
});
const mapDisactcProps = { getApeNFTByUser,getApeNFTS};
export default connect(structuredSelector, mapDisactcProps)(GalleryList);
