import React from 'react'
import {Link} from 'react-router-dom';
import {openseaUrl} from "../../utlis/index"

const BuyCredit = (props)=>{

  return(
    <div
    className="ape_on_boarding pt-0"
    style={{
      backgroundImage: "url('/assets/images/bg-without-rope-2.png')",
      backgroundPosition: "center",
      minHeight: "700px"
    }}
  >
    <div className="ape_top_banner">
      <div className="d-flex">
        <div className="flex-shrink-0">
          <img
            src="/assets/images/character-avatar.png"
            className="img-fluid character-avatar"
            width="120"
            style={{marginLeft: '1rem'}}
          />
          <h5 className="avatar-text ms-4">Capt Poly</h5>
        </div>

        <div className="mt-5-5 text-center" style={{width:'100%',marginRight:"110px"}}>
          <h2 className="heading-3 mb-0 mt-5" dangerouslySetInnerHTML={{ __html: props.title}}></h2>
          <h4 className="text-center">{props.subTitle}</h4>
        </div>
      </div>

      <hr />

      <div className="row justify-content-center mt-1">
        <div className="col-md-8">
          <p className="p-2" ><span dangerouslySetInnerHTML={{ __html: props.details}} />
           
          </p>
          {/* <div className="btns text-center">
          <a href="https://opensea.io/collection/apein-finance-shop" target="_blank"  className="btn btn-theme-primary font-18" style={{padding:"0.9rem 3.5rem",paddingTop:"1.23rem"}}> 
                Buy On Opensea
            </a>
          </div> */}
        </div>
      </div>
    </div>
  </div>


  
  )

}


export default BuyCredit