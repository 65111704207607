import React from "react";

const step1 = (props) => {
  return (
    <>
        <div className="prize_comment">
          <h4 className="top mb-0">HEAD</h4>
          <img src="/assets/images/prize-star-icon.png" className="img-fluid" width={30} />
          <img src="/assets/images/prize-star-icon.png" className="img-fluid" width={30} />
          <h4 className="bottom">Snowman</h4>
        </div>
        <div className="mt-3 ape-main" style={{height: '350px'}}>
          <img src="/assets/images/snowman.png" className="img-fluid" />
        </div>
        <div className="mt-3">
          {/* <button className="btn btn-theme-regular "><img src="/assets/new_images/ape_heading_title.png" className="img-fluid" /></button> */}
        </div>
      </>
  );
};

export default step1;
