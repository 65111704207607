import React from "react";
import ImageBlock from "../../../components/ApeDetail/DetailBlock/ImageBlock"

const ImageBox = (props) => {
  const {ape} = props;
  return (
    <div className="reward-box gallery_box"  onClick={()=>{props.setApe(ape)}}>
      <h4 className="text-center">{ape.name}</h4>

      <ImageBlock  image={ape.image} alpha={ape.is_alpha} />

      <p className="text-font-primary text-center" style={{cursor:"pointer"}}>
        <img src="/assets/images/blue_eye.svg" className="img-fluid" />
        &nbsp;View This Ape
      </p>
    </div>
  );
};

export default ImageBox;
