import React from "react"


const Frame = (props) =>{
  const {minted,onCheck,mintIds} = props
  const {data} = props
  return(
    <>
      {minted ?
          <div className="migrateFrame">
          <h4>{data.name}</h4>
          <div className="migrateFrameInner text-center">
            <div className="py-3">
              <img src={data.image} className="img-fluid" />
              <div className="chain_icon chain_ava_icon"><img src="/assets/new_images/avax-img-icon.png" className="img-fluid" /></div>
            </div>
          </div>
        </div>
        : 
        <div className="migrateFrame" >
          <h4>{data.name}</h4>
          <div className="migrateFrameInner text-center" onClick={(e)=>{onCheck(data.id)}}>
            <div className="frameBlocked">&nbsp;</div>
            <div className="py-3">
              <div className="check_mark" >
                <input type="checkbox" className="check_box"  id={`checkbox_${data.id}`} checked={mintIds.includes(data.id)} /><label >&nbsp;</label>
              </div>
              <img src={data.image} className="img-fluid" />
              <div className="chain_icon"><img src="/assets/new_images/eth-img-icon.png" className="img-fluid" /></div>
            </div>
          </div>
        </div>
      }
      </>
    
  )
}


export default Frame