import React,{useEffect} from "react";
import {Link} from 'react-router-dom';
import * as constants from "../../../constants"

import ImageBlock from "./ImageBlock";

const DetailBlock = (props) => {
  const {buildApeObject,currentApeContract}=props
  const ape = buildApeObject.data;

 
  const shareTwitter = (app)=>{
    window.open("https://twitter.com/share?text=Check out my new friend on Ape Island: Avalanche Expansion! 🔺&url="+constants.DOMAIN_NAME+"gallery/s1/"+app.id+"&hashtags=ApeIn,ApeIsland,Avalanche");
    constants.GPageEvent("Social","Twitter-Share","Share");
  }
  return (
    <div
      className="ape_on_boarding pt-0"
      style={{
        backgroundImage: "url('/assets/images/bg-without-rope-2.png')",
        backgroundPosition: "center",
      }}
    >
      <div className="ape_top_banner">
        <div className="d-flex">
          <div className="flex-shrink-0">
          <img src="/assets/images/character-avatar.png" className="img-fluid character-avatar" width="120" />
            <h6 className="avatar-text ms-3">Capt Poly</h6>
          </div>

          <div className="mt-5-5 detailBlock_head_section" style={{paddingLeft:"5rem"}}>
            <h2 className="heading-3 mb-0 mt-1">Hooray !!</h2>
            <h4>You Bought: {ape.name}</h4>
          </div>
        </div>

        <hr />

        <div className="row justify-content-center mt-1">
          <div className="col-md-8">
            <p className="p-2 text-justify">
              {/* <span dangerouslySetInnerHTML={{ __html: props.details }} /> */}
              <strong className="font-18">
                Congrats!!! You now have {ape.name} in your crew.
              </strong>

              <span className="font-18 d-block">
              You can go back to the Ape Builder and generate more mates, or you can view your new ape in the Ape Gallery.
              </span>
            </p>
            <ImageBlock  image={ape.image} alpha={ape.is_alpha} />

            <div className="btns text-center mt-2">
              <Link to="/gallery" class="btn  gallery_btn px-3 font-18">View In Gallery</Link>
              <button className="btn btn-theme-primary px-3 font-18"  style={{height:"65px"}} onClick={()=>{shareTwitter(ape)}}>
                Share Your Mate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailBlock;
