import React from "react"


const MetaMask = () =>{
  return(
    <footer className="py-5">
      <div className="container">
        <div className="col-md-4 float-end">
          <div className="card metamask-alert">
            <div className="card-header"><h2 style={{paddingTop: '0.75rem'}}>Connect With MetaMask</h2></div>
            <div className="card-body">
              <p className="px-3 pt-2">In order to continue you will need to<br />connect your MetaMask wallet to Ape Island.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}


export default MetaMask