import ReactGA from "react-ga";

export const ETH_ENV = process.env.ETH_ENV;
export const DOMAIN_NAME = process.env.DOMAIN_NAME;
export const PGFK_ADDR = process.env.PGFK_ADDR;

export const APETST1_ADDR = process.env.APETST1_ADDR;
export const APETST2_ADDR = process.env.APETST2_ADDR;
export const APETST3_ADDR = process.env.APETST3_ADDR;
export const PGFKSTAG_ADDR = process.env.PGFKSTAG_ADDR;

export const APE_TOKEN_ERC20_ADDR = process.env.APE_TOKEN_ERC20_ADDR;


export const APETST1_CREDIT = process.env.APETST1_CREDIT;
export const APETST2_CREDIT = process.env.APETST2_CREDIT;
export const APETST3_CREDIT = process.env.APETST3_CREDIT;

export const APE_TOKEN_ADDR = process.env.APE_TOKEN_ADDR;
export const APE_TOKEN_SEASON1_ADDR = process.env.APE_TOKEN_SEASON1_ADDR;
export const APE_TOKEN_SEASON2_ADDR = process.env.APE_TOKEN_SEASON2_ADDR;


export const APE_ETH_TOKEN_SEASON1_ADDR = process.env.APE_ETH_TOKEN_SEASON1_ADDR;
export const APE_ETH_TOKEN_SEASON2_ADDR = process.env.APE_ETH_TOKEN_SEASON2_ADDR;
export const APE_ETH_TOKEN_SEASON3_ADDR = process.env.APE_ETH_TOKEN_SEASON3_ADDR;
export const APE_ETH_TOKEN_OFFSPRING_ADDR = process.env.APE_ETH_TOKEN_OFFSPRING_ADDR;

export const APE_BREED_ADDR = process.env.APE_BREED_ADDR;

export const PGU_TOKEN_ADDR = process.env.PGU_TOKEN_ADDR;

export const IMG_GENERATE_CREDIT = process.env.IMG_GENERATE_CREDIT;

export const APE_CREDIT_TOKEN = process.env.APE_CREDIT_TOKEN;
export const APE_WEEK_HAND_CREDIT = process.env.APE_WEEK_HAND_CREDIT;
export const APE_USED_RUGS_CREDIT = process.env.APE_USED_RUGS_CREDIT;
export const APE_WORTHLESS_BANANA_CREDIT = process.env.APE_WORTHLESS_BANANA_CREDIT;
export const APE_UNIQUE_RUG_CREDIT = process.env.APE_UNIQUE_RUG_CREDIT;
export const APE_VALENTINE_CREDIT = process.env.APE_VALENTINE_CREDIT;
export const APE_ISLAND_CREDIT = process.env.APE_ISLAND_CREDIT;
export const APE_MONKEY_HEAD_CREDIT = process.env.APE_MONKEY_HEAD_CREDIT;
export const APE_CHOCOLATE_CREDIT = process.env.APE_CHOCOLATE_CREDIT;
export const PGFKSTAG_CREDIT = process.env.PGFKSTAG_CREDIT;

export const ALLOW_TOKEN_SUPPLY = process.env.ALLOW_TOKEN_SUPPLY;
export const UNIQUE_KEY_SEC = process.env.UNIQUE_KEY_SEC;



export const GOOGLE_ANALYTICS_ID = process.env.GOOGLE_ANALYTICS_ID
if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID)
} else {
  ReactGA.initialize('test', { testMode: true, debug: true })
}

export const React_GA = ReactGA;

export const GPageView = (title,path)=>{
  if(window.gtag){ 
    window.gtag('config', GOOGLE_ANALYTICS_ID, {
      'page_title' : title,
      'page_path': path
    });
  }
}

export const GPageEvent = (name,label,category,)=>{
  if(window.gtag){ 
    window.gtag('event', name, {
      'event_label': label,
      'event_category': category,
      'non_interaction': true
    }); 
  }
}


export const GScreenView = (screen_name)=>{
  if(window.gtag){ 
    window.gtag('event', 'screen_view', {
      'app_name': 'Ape Island Season 2',
      'screen_name' : screen_name
    });
  }
}
