import React from 'react'
import {Link} from 'react-router-dom';
const introBlock = (props)=>{


  return(

    <div
    className="ape_on_boarding pt-0"
    style={{
      backgroundImage: "url('/assets/new_images/bg-without-rope-2.png')",
      backgroundPosition: "center",
      minHeight: "700px"
    }}
  >
    <div className="ape_top_banner">
      <div className="d-flex">
        <div className="flex-shrink-0">
          <img
            src="/assets/new_images/character-avatar.png"
            className="img-fluid character-avatar"
            width="120"
            style={{marginLeft: '1rem'}}
          />
          <h5 className="avatar-text ms-4">Capt Poly</h5>
        </div>

        <div className="mt-5-5 text-center" style={{paddingLeft: props.paddingLeft ? props.paddingLeft: '25px'}}>
          <h2 className="heading-3 mb-0 mt-5">{props.title}</h2>
          <h4>{props.subTitle}</h4>
        </div>
      </div>

      <hr />

      <div className="row justify-content-center mt-1">
        <div className="col-md-8">
          <p className="p-2" style={{height: "250px", overflow: 'auto'}}><span dangerouslySetInnerHTML={{ __html: props.details}} />
           
          </p>
          <div className="btns text-center">
            {props.currentStep > 0? 
            <button style={{width: "40%"}} className="btn btn-previous me-1 font-18" onClick={()=>{props.changeStep(props.currentStep-1)}}>
              Previous
            </button>
            :
            <button style={{width: "40%"}} className="btn btn-previous me-1 font-18" disabled={true}>
              Previous
            </button>
            }

            {props.currentStep < (props.maxStep-1) ? 
            <button className="btn btn-theme-primary font-18" style={{width: "40%"}} onClick={()=>{props.changeStep(props.currentStep+1)}}>
              Continue
            </button>
            :
            <Link to={`/app_maker`}  className="btn btn-theme-primary font-18" style={{paddingTop:"1.23rem"}}> 
              Ape In!! 
            </Link>
            }
          </div>
        </div>
      </div>
    </div>
  </div>


  
  )

}


export default introBlock