import React from "react";

const ImageBlock = (props) => {
  return (
    <div className="reward-box ape_box">
      <div
        className="reward-frame test text-center"
        style={{ padding: "1rem 4.5 rem !important" }}
      >
        <span className="info-success">
        </span>
      
        <img src={props.image} width="126" className="gimg mt-0"/>
      </div>
    </div>
  );
};

export default ImageBlock;
