import React from "react";

import {baseImageApe} from "../../utlis";

const ApeBlock = (props) => {
  const {ape} = props;
  return (
    <>
      <section className="services" id="services">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="base-circle">
                <div className="parts">
                  {props.children}  
                  
                </div>  
                <div className="base-content-wrapper">
                  <div className="base-content-item d-block">
                    <p className="description">
                      <img
                        src={ape ? ape.image : baseImageApe()}
                        width="500"
                      />
                      {ape && ape.image==baseImageApe() &&
                        <div><h6 className="text-white">Generating Ape</h6></div>
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ApeBlock;
