import React, {useState} from 'react'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";


import ApeBlockNew from '../../components/ApeBody/ApeBlockNew'
import ApeFrame from '../../components/Migrate/Frame'
import ApeDetail from '../../components/Migrate/Details'

import {getApeForMigration,mintSignature} from "../../actions/connector";

import {APE_ETH_TOKEN_SEASON1_ADDR,APE_ETH_TOKEN_SEASON2_ADDR,APE_ETH_TOKEN_SEASON3_ADDR,APE_ETH_TOKEN_OFFSPRING_ADDR} from "../../constants/index"

import {etherUrl} from "../../utlis/index"

const MigrateApes = (props) => {
  const {user} = props;
  const [currentSeason,setCurrentSeason] = useState('season1');
  const [mintIds,setMintIds] = useState([]);
  const [seasonData,setCurrentSeasonData] = useState({});
  const [loadingData,setLoadingData] = useState(true);
  const [mintStarted,setMintStarted] = useState(false);
  const [mintResponse,setMintResponse] = useState(false);
  const [error,setError] = useState(false);

  const addRemoveMintIds =(id)=>{
     if(mintStarted){
      return
     }
    setError(false);
    setMintResponse(false);
    const mintArray = [...mintIds];
    const index = mintArray.indexOf(id);
    if (index > -1) {
      mintArray.splice(index, 1);
    }else{
      mintArray.push(id)
    }
    if(mintArray.length > 20){
      alert("Max 20 can be selected at one time.");
      return;
    }
    setMintIds(mintArray);
  }

  const setSeason=(season)=>{
    if(mintStarted){
      return
     }

    setCurrentSeason(season);
    loadMigrationApe(season);
  }


  const getSeasonAddr=(season)=>{
    switch(season){
      case 'season1': return APE_ETH_TOKEN_SEASON1_ADDR;
      case 'season2': return APE_ETH_TOKEN_SEASON2_ADDR;
      case 'season3': return APE_ETH_TOKEN_SEASON3_ADDR;
      case 'offspring': return APE_ETH_TOKEN_OFFSPRING_ADDR;
    }
  }


  const loadMigrationApe = (season)=>{
    setLoadingData(true);
    setCurrentSeasonData({});
    setMintIds([]);
    // make a function which return object with minted / not minted
    getApeForMigration(season,user.user.eth_address,getSeasonAddr(season),(data)=>{
      if(data.season == document.getElementById('season_select').value){
        setCurrentSeasonData(data);
        setLoadingData(false);
      }
    })
  }

  useState(()=>{
    loadMigrationApe(currentSeason);
  },[])



  const mintToken = ()=>{
    if(mintStarted){
      return
     }
    let apeData = {
      owner: user.user.eth_address,
      token_ids: mintIds.join(","),
      season_name: currentSeason,
      contractAddr: getSeasonAddr(currentSeason),
    }
    setMintStarted(true);
    setMintResponse(false);
    setError(false);
    mintSignature(apeData,user.user,(type,data)=>{
      setMintStarted(false);
      setMintResponse({type:type,data:data})
      if(type=='success'){
        setMintIds([]);
        loadMigrationApe(currentSeason);
      }else{
        setError(data.message);
      }
    })
  }



  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="text-center">
            <h2 className="mg-h2 mb-0">Select Your Apes To Migrate</h2>
            <h5 className="mg-h5 mt-0">(Up to 20 can be selected at one time)</h5>
          </div>
          <div className="text-center">
            <div className="overlay_select" >Selected: 
              <select id="season_select" value={currentSeason}  onChange={(e)=>{setSeason(e.target.value)}} >
                <option value="season1">Season 1</option>
                <option value="season2">Season 2</option>
                <option value="season3">Season 3</option>
                <option value="offspring">Offspring</option>
              </select>
            </div>
            <br/>
            {error &&
           <    div className='alert alert-danger'>{error}</div> 
            }
          </div>
          <div className="row justify-content-evenly migrate_ape">
            {loadingData ?
              <div className="col-md-12 text-center pt-3"> <br/> <h4>Loading...</h4> </div> 
            :
            <>
              <>
              {seasonData.migrationToken.map((d)=>{
                return <div className="col-md-4 pt-3">
                  <ApeFrame minted={false} data={d} onCheck={addRemoveMintIds} mintIds={mintIds}/>
                </div>
              })}
              </>
              <>
              {seasonData.mintedTokens.map((d)=>{
                return <div className="col-md-4 pt-3">
                  <ApeFrame minted={true} data={d}/>
                </div>
              })}
              </>
            </>
            }
          </div>
        </div>
        <div className="col-md-6 pt-3">
        {(mintResponse!=false && mintResponse.type =='success' )?
          <>
           <div className="apeMakerBuiltMain">
            <div className="apeFlipBox text-center" style={{marginTop:"200px"}}>
                <h3> Success! Your selected apes have successfully migrated to Avalanche. Visit the gallery to see their details.</h3>
                <div className="bodyDetailPart">
                  <div className="wooden_cloud_button text-center" style={{cursor:"pointer",margin:"auto"}}>
                  <a href={etherUrl('/tx/'+mintResponse.data.transactionHash)} target="_blank" className="text-center font-22" style={{marginTop:"-2px", lineHeight: '1.25'}}>View</a> 
                 </div>
                 </div>
              </div>
          </div>
          </>
         :   
        <>
          {!loadingData && seasonData.migrationToken.length == 0 ?
            <>
            <div className="apeMakerBuiltMain">
              <div className="apeFlipBox text-center" style={{marginTop:"200px"}}>
                  <h3> No apes have been found to migrate. If you believe this is in error, please reach out to one of our social channels.</h3>
                </div>
            </div>
            </>
            :
            <>
              {!loadingData &&
              <ApeDetail mintIds={mintIds} migrationToken={seasonData.migrationToken} currentSeason={currentSeason} mintToken={mintToken}  mintResponse={mintResponse} mintStarted={mintStarted}/>
              }
            </>

          } 
        </>
      }
          
          
        </div>
      </div>
    </>
  )
}

// @TODO future update review
const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
});
const mapDisactcProps = {};
export default connect(structuredSelector, mapDisactcProps)(MigrateApes);