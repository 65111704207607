import React from "react";

const step5 = (props) => {
  return (
    <>
        <div className="mt-3 ape-main-new py-4">
        <img src="/assets/images/pirate-ape.gif" className="img-fluid" width="300" />
      </div>
      </>
  );
};

export default step5;
