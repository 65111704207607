import React,{useState,useEffect} from 'react';
import ApeBody from '../../components/ApeBody'

import {Link,withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {resetBuildApe,apeBuy} from "../../actions"

import StatsPanel from "../../components/StatsPanel"
import ApeDetail from "../../components/ApeDetail/"
import DetailBlock from "../../components/ApeDetail/DetailBlock"
import * as constants from "../../constants"

import {baseImageApe} from "../../utlis"

const ApePageBuy = (props) =>{
  const {user,buildApeObject} = props;
  const [buyProcess,setBuyProcess] = useState(false);
  const [showOverlay,setOverlay] = useState(true);
  const [buyApeObject,setBuyApeObject] = useState(buildApeObject);
  const currentApeContract = props.location.query.currentApeContract;


  let ape = buyApeObject.data;

  let sellSuccess = (apeObj,isAlpha,data)=>{
    data={eth_address:user.user.eth_address,is_alpha:isAlpha,user_id:user.user.id,ape_id:apeObj.id,tx_hash:data.transactionHash,contract_address:currentApeContract}
    props.apeBuy(data);
    
    buyApeObject.data.is_alpha = isAlpha;
    ape = buyApeObject.data;
    setBuyApeObject(buyApeObject);
    props.resetBuildApe();

    setBuyProcess(true);
    
    constants.GPageEvent("Sell Success","Ape Sold",isAlpha? "Alpha-Sale" : "Normal-Sale");

  }

  let sellError = ()=>{
      props.resetBuildApe();
      
  }

  useEffect(()=>{
    constants.GPageView("Buy Page","/ape_buy");
    constants.GScreenView("Ape Buy Screen");
  },[])

  // for updating image only
    useEffect(()=>{
      if(buyApeObject.data!=false && buyApeObject.data.image ==baseImageApe()){ 
        const bdata = {...buyApeObject.data} 
        bdata.image = buildApeObject.data.image;
        ape = bdata;
        buyApeObject.data = bdata
        setBuyApeObject(buyApeObject);
      }
    },[buildApeObject.data.image])

  let objectShow = (apeObj)=>{
    return {
        "description": "An ape from the Polyient Games Ape Island Series.", 
        "external_url": "", 
        "image": apeObj.picture, 
        "name": apeObj.name,
        "id": apeObj.id,
        "attributes": [
           
          {
            "trait_type": "Attack", 
            "value": apeObj.state_attack
          }, 
          {
            "trait_type": "Defense", 
            "value": apeObj.state_defense
          }, 
          {
            "trait_type": "Speed", 
            "value": apeObj.state_speed
          },
          {
            "trait_type": "Ability", 
            "value": apeObj.state_ability
          }, 
          {
            "trait_type": "Nature", 
            "value": apeObj.state_nature
          }
        ]
      }
    }


  return(
    <div className="container">
    {buyApeObject.data &&
      <StatsPanel ape={buyApeObject.data}/>
    }

    <div className="row">
      <div className="col-md-6 pt-5">
      <ApeBody ape={buyApeObject.data} showOverlay={showOverlay} currentApeContract={currentApeContract}/>
      <div className="container pt-5 mt-5  ">
        <div className="row justify-content-center">
            <br/>
            {ape  &&
              <button className="overlay_btn align-center" onClick={()=>{setOverlay(!showOverlay)}}>{showOverlay ? "Turn Off Overlay" : "Turn On Overlay"} </button>
            }
            
            {false &&
                <>
                <div className="col-md-12 text-center mt-5">
                <a href="#" className="themeBtn my-4">
                        Go Alpha
                 </a>
               </div>  
               <br />
              <div className="col-md-12 text-center mt-5">
                <strong className="mt-3">
                  <img src="/assets/images/checked.svg" width="30" /> Going Alpha,
                  Keeps This Ape Unique 1:1
                </strong>
              </div>
               <br />
              
              </>
            }   
     
        </div>
      </div>
      </div>
      <div className="col-md-6">
        {buyProcess ?
          <DetailBlock buildApeObject={buyApeObject} currentApeContract={currentApeContract}/>
        :
          <>
          <ApeDetail sellSuccess={sellSuccess} sellError={sellError} currentApeContract={currentApeContract}/>
          
          </>
        }
        
      </div>
    </div>
    </div>

  )
}

const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
  buildApeObject: (state) => state.buildApe,
  apes: (state) => state.apes,
})
const mapDisactcProps = { resetBuildApe,apeBuy };

export default connect(structuredSelector,mapDisactcProps)(ApePageBuy)