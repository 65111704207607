import React,{useEffect,useState} from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { metamaskLogin, checkLogin,loginReset } from '../../actions';
import Navbar from "../../components/NavBar";
import * as constants from "../../constants";

import MetaMaskAlert from "../../components/Alert/MetaMask"
import InfoAlert from "../../components/Alert/Info"
import DangerAlert from "../../components/Alert/DangerAlert"
import SuccessAlert from "../../components/Alert/SuccessAlert"
import WarningAlert from "../../components/Alert/WarningAlert"

import * as contents from "../../constants"

const Login = (props) => {
  const {user} = props;
  const [errorPopup, setErrorPopup] = useState(!user.network);


  const chkNetowrkError = ()=>{
    setErrorPopup(!user.network)
  }

  
  useEffect(()=>{
    if(user.login){
      props.history.push("/how_to_play");
    }else{
      chkNetowrkError();
      props.checkLogin();
    }
  },[user.login]);

  useEffect(()=>{
    chkNetowrkError();
  },[user.network]);

  const  metamaskLoginBtn = ()=>{
    props.loginReset();
    // if(user.network){ 
      if (typeof window.ethereum !== 'undefined') {
        props.metamaskLogin();
      }else{
        window.open("https://metamask.app.link/dapp/"+contents.DOMAIN_NAME.replace("https://", "").replace("http://", "")+"/dashboard?login=metamask");
      } 
    // }else{
    //   chkNetowrkError();
    // }
  }



  return (
      <div className="container">
        {errorPopup &&
          <div style={{position:'fixed',top:'0',left:'0',width:'100%',height:'100%',background:"#00000075",zIndex:"100"}} onClick={(e)=>{if(e.target.style.position =='fixed'){setErrorPopup(false)}}}>
            <div style={{width:"640px",height:"auto",margin:"0 auto", padding:"10px",position:"relative",marginTop:"15%"}}>
            <DangerAlert title="Ethereum Network Notice
             " description={contents.ETH_ENV =='development' ? "You need to be connected to Avalanche Fuji Testnet to play on this parallel universe of Ape Island. Please change your network settings to continue." : "You need to be connected to Avalanche Mainnet to play on Ape Island. Please change your network settings to continue."} full={true}/>
            </div>
          </div>
        }
        <Navbar />

        <div className="row justify-content-center">
 
  			<div className="col-md-6">
          
          <div className="ape_on_boarding pt-0" style={{backgroundImage: "url('/assets/new_images/bg-without-rope.png')", backgroundPosition: "center"}}>
            <div className="ape_top_banner text-center">
              <img src="/assets/new_images/Logo-v1.png" className="img-fluid" width="420" />
              <div className="row justify-content-center mt-3">
                <div className="col-md-8 mt-5">
                  <div className="connect-box py-3 px-2">
                    <h2 className="heading-2">Connect With MetaMask</h2>
                    <button className="btn btn-connect pt-3 pb-4 mt-1 font-18" onClick={()=>{metamaskLoginBtn()}} >Connect Wallet</button>
                  </div>
                  <br/>
                  <p style={{color:"#8b6947"}}><b>This project is still in beta. Use at your own risk.</b></p>
                </div>
              </div>
            </div>
          </div>
  			</div>
        
        <MetaMaskAlert title="Connect With MetaMask" description="In order to continue you will need to connect your MetaMask wallet to Ape Island."/>
        
        
  		</div>
    </div>
  );
};

// @TODO future update review
const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
});
const mapDisactcProps = {metamaskLogin, checkLogin,loginReset};
export default connect(structuredSelector, mapDisactcProps)(Login);
