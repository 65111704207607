import React from "react";


import {Link,withRouter} from 'react-router-dom';
const Home = () => {
  return (
      <div className="container animate__animated animate__bounce ">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="ape_on_boarding">
              <div className="ape_top_banner text-center">
                <img
                  src="assets/new_images/logo.png"
                  className="img-fluid"
                  width="420"
                />
                <div style={{position: 'relative',top:"-30px"}}>
                <img src="assets/new_images/aug_image.png" width="250" />
                <h1 className="logo" style={{marginTop:'-30px'}}>Welcome To Ape Island</h1>
                </div>
                
                <Link to={'/login'} className="themeBtn my-4">
                Enter Ape Island  
                </Link>
                
                
              </div>
            </div>
            
          </div>
          <div className="clearfix"></div>
          <div className="col-md-5">
            <h2 className="version mt-5 text-center">
              <img src="/assets/images/info_circle_green.png" /> Ape Island / Avalanche Update / V.1.0.0
            </h2>
          </div>
        </div>
      </div>
  );
};

export default Home;
