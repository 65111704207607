import React,{useEffect} from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {Link,withRouter} from 'react-router-dom';
import ApePart from "./ApePart";
import ApeBlock from  "./ApeBlock";

import { findUserApe } from '../../actions';
import {baseImageApe} from "../../utlis";

const ApeBody = (props) => {
  let {ape,showOverlay,currentApeContract} = props;

  let interVal = null;
  let find_image =(picture)=>{
    console.log("picture",picture);
    console.log("baseImageApe()",baseImageApe());
    if(picture.includes(baseImageApe())){
      props.findUserApe(props.user.user.eth_address,ape.id,currentApeContract)
    }else{
      if(interVal!=null){
        clearInterval(interVal);
        interVal = null;
      }
    }
  }
 
  if(ape){  
    useEffect(()=>{
        interVal = setInterval(()=>{
          find_image(ape.image);
        },10000);
        return ()=>{
          clearInterval(interVal);
        }
    },[ape.image]);
  }

  return (
    <>
    {ape? 
     <ApeBlock  ape={ape}>
       {showOverlay &&
        <ApePart  ape={ape}/> 
       }
     </ApeBlock>
      :
      <div className="ape_maker_on_boarding" style={{backgroundImage: 'url("/assets/images/ape_blank_bg.png")', backgroundPosition: 'top', paddingTop: '6rem'}}>
        <div className="ape_top_banner text-center">
          <img src="/assets/images/character.gif" className="img-fluid" />
        </div>
      </div>
      }

       
    </>
  );
};


// @TODO future update review
const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
  apes: (state) => state.apes,
});
const mapDisactcProps = {findUserApe};
export default connect(structuredSelector, mapDisactcProps)(ApeBody);

