import React, {
  Suspense,
  StrictMode,
  Component,
  useEffect,
  useState,
} from "react";
import {
  Route,
  BrowserRouter,
  useLocation,
  withRouter,
  Switch,
} from "react-router-dom";

import { LayoutOne, LayoutTwo, LayoutThree, LayoutApeMigrate } from "../layout";

import { Provider } from "react-redux";
import configureStore from "../configureStore";
const store = configureStore();

import Tour from "../components/Tour";
import Home from "../pages/Home";
import Login from "../pages/Login";
import HowToPlay from "../pages/HowToPlay";
import GetCredits from "../pages/GetCredits";
import AppMaker from "../pages/AppMaker";
import ApePage from "../pages/ApePage";
import ApeGallery from "../pages/ApeGallery";
import ApePageBuy from "../pages/ApePageBuy";
import ApeCheck from "../pages/ApeCheck";
import MigrateApes from "../pages/MigrateApes";

import DangerAlert from "../components/Alert/DangerAlert"

import Breeding from "../pages/Breeding";

import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";

// const GOOGLE_ANALYTICS_ID = process.env.GOOGLE_ANALYTICS_ID
// if (typeof GOOGLE_ANALYTICS_ID === 'string') {
//   // hotjar.initialize(2183853, 6);
//   ReactGA.initialize(GOOGLE_ANALYTICS_ID);
// } else {
//   ReactGA.initialize('test', { testMode: true, debug: true })
// }

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);
const App = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);


  return (<>
    {width > 768 ? 
    (
      <StrictMode>
      <Provider store={store}>
        <Suspense fallback={null}>
          <BrowserRouter>
            <ScrollToTop>
              <Switch>
                <RouteWrapper
                  exact
                  path="/"
                  component={Home}
                  layout={LayoutOne}
                />
                {/* <RouteWrapper
                  exact
                  path="/mating"
                  component={Breeding}
                  layout={LayoutTwo}
                  login={true}
                /> */}
                <RouteWrapper
                  exact
                  path="/login"
                  component={Login}
                  layout={LayoutOne}
                />
                <RouteWrapper
                  exact
                  path="/how_to_play"
                  render={(props) => (
                    <Tour {...props} breakpoint={breakpoint} />
                  )}
                  component={HowToPlay}
                  layout={LayoutTwo}
                />
                <RouteWrapper
                  exact
                  path="/buy_credits"
                  component={GetCredits}
                  layout={LayoutTwo}
                  login={true}
                />
                <RouteWrapper
                  exact
                  path="/migrate-eth-apes"
                  component={MigrateApes}
                  layout={LayoutTwo}
                  login={true}
                />
                <RouteWrapper
                  exact
                  path="/app_maker"
                  component={AppMaker}
                  layout={LayoutThree}
                  login={true}
                />
                <RouteWrapper
                  exact
                  path="/apepage"
                  component={ApePage}
                  layout={LayoutTwo}
                  login={true}
                />
                <RouteWrapper
                  exact
                  path="/ape_buy"
                  component={ApePageBuy}
                  layout={LayoutTwo}
                  login={true}
                />
                <RouteWrapper
                  exact
                  path="/gallery"
                  component={ApeGallery}
                  layout={LayoutTwo}
                  login={true}
                />
                <RouteWrapper
                  path="/gallery/:id"
                  component={ApeGallery}
                  layout={LayoutTwo}
                  login={true}
                />
                <RouteWrapper
                  path="/run_ape_chk/:id"
                  component={ApeCheck}
                  layout={LayoutTwo}
                />
              </Switch>
            </ScrollToTop>
          </BrowserRouter>
        </Suspense>
      </Provider>
    </StrictMode>) : (
 
          <DangerAlert title="No Mobile Access" description="Ape Island is currently a desktop only game. Please visit via a web3 enabled desktop browser. See you around the island!"/>
    )}
    </>
    
  );
};

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props} login={rest.login}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

export default App;
