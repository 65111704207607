import {fetchReq,postReq } from './base';
import detectEthereumProvider from '@metamask/detect-provider';
import APE_BREED_ERC721 from "../abi/APE_BREED_ERC721"
import APE_ETH from "../abi/APE_ETH"
import APE_ERC721 from "../abi/APE_ERC721"
import ERC20 from "../abi/ERC20"
import ERC721 from "../abi/ERC721"
import ERC1155 from "../abi/ERC1155"
import * as contents from "../constants"

import chainObject,{isChainAllow,getNetwork} from "../constants/chain"


const bigInt = require("big-integer");

export const web3 = new Web3();
export const cargo = new window.cargoJs.Cargo({ network: contents.ETH_ENV });

export function cargoRegister(obj) {
  return dispatch => {
      dispatch({
        type: 'LOGIN_SUCCESS',
        json:{data:obj.user}
      })
      // getVouchersData(obj.user,true)(dispatch);
      // getPGFKBalance(obj.user.eth_address)(dispatch);
      getTokensByUser(obj.user)(dispatch);
      getApePrice()(dispatch);
      // getApePrice()
    return

      dispatch({ type: "CARGO_REQUEST" });
      cargo.api.authenticate().then((response) => {
        if (response.status === 200) {
          obj.user['cargo_token'] = response.data.token;
          obj.user['cargo_contracts'] = {};
          cargo.api.getContracts({ useAuthToken: response.data.token, limit: 3000 }).then(cres => {
            let tokens_contract = {};
            tokens_contract['ape1'] = cres.data.results.find(element => element.address.toLowerCase() == contents.APETST1_ADDR.toLowerCase());
            tokens_contract['ape2'] = cres.data.results.find(element => element.address.toLowerCase() == contents.APETST2_ADDR.toLowerCase());
            tokens_contract['ape3'] = cres.data.results.find(element => element.address.toLowerCase() == contents.APETST3_ADDR.toLowerCase());
            tokens_contract['pgfk'] = cres.data.results.find(element => element.address.toLowerCase() == contents.PGFKSTAG_ADDR.toLowerCase());
            obj.user['cargo_contracts'] = tokens_contract;
            dispatch({
              type: 'LOGIN_SUCCESS',
              json:{data:obj.user}
            })
          });
        } else {
          registerAtCargo(obj,dispatch);
        }
      });
  };
} 

function registerAtCargo(logged_user,dispatch){
  cargo.api.register().then(response => {
    if (response.status === 200) {
      return cargoRegister(logged_user)(dispatch)
    }
  });
}



function registerUser(userdata, dispatch) {
  // return postReq('/api/v1/user/register', userdata, 'LOGIN')((data) => {
  //   dispatch(data)
  // });
  
  return postReq('/api/v1/user/register', userdata, 'GET_USER')((data) => { 
    if (data.type == 'GET_USER_SUCCESS') {
      if (data.json.type == 'error') {
          dispatch({
            type: 'LOGIN_ERROR_CALL',
            json: {data:logged_user}
          })
      } else {
        return cargoRegister({user:data.json.data})(dispatch)
      }
    }
  })


}


function metamaskLoginChk(dispatch) {
  
  if (ethereum.isMetaMask && ethereum.selectedAddress != (null || undefined)) { 
    ethereum.on('accountsChanged', function (accounts) {
      window.location.reload();
    });
    ethereum.on('chainChanged', function (accounts) {
      window.location.reload();
    });
  
      detectEthereumProvider().then(provider=>{
        window.provider = provider;
        web3.setProvider(provider);
        web3.eth.net.getNetworkType().then(async(network_type) => {

          let network = {}
          network['type'] = network_type;
          network['chainId'] = await ethereum.request({ method: 'eth_chainId' });
          network['networkVersion'] = await ethereum.request({ method: 'net_version' });
					// @Todo - validate only dev / prod network base on env
          if(isChainAllow(network['chainId'])){ 
            let loggedUser ={};
            loggedUser['eth_address']= ethereum.selectedAddress
              registerUser(loggedUser, dispatch)
          }else{
            let chainId = contents.ETH_ENV =='development' ?'0xa869' :'0xa86a' ;
            walletSwitchEthereumChain(chainId,(data)=>{
              if(data.type =='error'){
                dispatch({
                  type: 'LOGIN_ERROR_CALL_NETWORK',
                  json: {}
                })
              }
            })

            
          }
        })
      });
    } 
 }


export async function walletSwitchEthereumChain(chainID,callback){
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: (chainID) }],
    });
    callback({type:"success",message:"network get switched to "+chainID});
  } catch (switchError) {
    if (switchError.code === 4902) {
      walletAddEthereumChain(chainID,(data)=>{
        if(data.type=='success'){
          walletSwitchEthereumChain(chainID,callback);
        }else{
          callback(data);
        }
      });
    }else{
      callback({type:"error",message:switchError.message});
    }
  }
  
}

export function getHaxChainId(id){
  return "0x"+(id).toString(16)
}

export async function walletAddEthereumChain(chainId,callback){
  chainId = parseInt(chainId);
  let chain = chainObject.find(v=> v.chainId == chainId);
  let object = { }
  if(!chain){
    callback({type:"error",message:"network chain - "+chainId+" not found!"});
    return 
  }else{
    object={ 
      chainId: getHaxChainId(chain.chainId), // A 0x-prefixed hexadecimal string
      chainName: chain.name,
      nativeCurrency: chain.nativeCurrency,
      rpcUrls: chain.rpc,
      blockExplorerUrls: (chain.explorers.length >0 ? chain.explorers.map((e)=>e.url) : null),
      iconUrls: []
    }
  }
  try {
    await ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [object],
    });
    callback({type:"success",message:"wallet added to chain"});
  } catch (addError) {
    callback({type:"error",message:addError.message});
  }

}
 

 export  function getPGFKBalance(wallet_address) {
  return dispatch => {
      const contract = new web3.eth.Contract(ERC721, contents.PGFK_ADDR);
       contract.methods.balanceOf(wallet_address).call().then((balance)=>{
        dispatch({
          type: 'PGFK_BALANCE',
          json:{data:balance}
        })
       });
  };
}

export  function getTotalSupply() {
  return async(dispatch) => {
    const apeContract = new web3.eth.Contract(APE_ERC721, contents.APE_TOKEN_ADDR);
    let  totalSupply = await apeContract.methods.totalSupply().call();
    dispatch({
      type: 'APE_SUPPLY',
      json:{totalSupply:totalSupply,remaing: parseInt(contents.ALLOW_TOKEN_SUPPLY) - parseInt(totalSupply)}
    })
  };
}

//breed

export async function makeElder(ape_id,season_addr,user,callback){
  console.log("ape_id,season_addr,user",ape_id,season_addr,user);
  try{ 
    const apeContract = new web3.eth.Contract(APE_BREED_ERC721, contents.APE_BREED_ADDR);
    const apeToken = new web3.eth.Contract(ERC20,contents.APE_TOKEN_ERC20_ADDR);

    let apeTokenPrice = await apeContract.methods.elderPrice(contents.APE_TOKEN_ERC20_ADDR).call();
    console.log("apeTokenPrice",apeTokenPrice);

    let approveAmount = await apeToken.methods.allowance(user.eth_address,contents.APE_BREED_ADDR).call();
    if(bigInt(apeTokenPrice) > bigInt(approveAmount)){
      window.web3Obj = web3;
      window.apeToken = apeToken;
      let price = (bigInt(2).pow(256).subtract(1)).toString();
      await apeToken.methods.approve(contents.APE_BREED_ADDR,price).send({from:user.eth_address});
    }
    console.log("ape_id,season_addr,contents.APE_TOKEN_ERC20_ADDR",ape_id,season_addr,contents.APE_TOKEN_ERC20_ADDR);
    let aTx = await apeContract.methods.makeElder(ape_id,season_addr,contents.APE_TOKEN_ERC20_ADDR).send({from:user.eth_address});
    let finalTx;
    do {
      finalTx = await web3.eth.getTransactionReceipt(aTx.transactionHash);
    }while (!finalTx.status);
    callback("success",finalTx);
        
  }catch(e){
    callback("error",e.message);
  }

}

export async function canBeElder(ape_id,season_addr){
  try{
    const apeContract = new web3.eth.Contract(APE_BREED_ERC721, contents.APE_BREED_ADDR);
    console.log("ape_id,season_addr",ape_id,season_addr);
    let breedCount = await apeContract.methods.mateCount(ape_id,season_addr).call();
    let maxBreedCount = await apeContract.methods.maxSeasonMateCount(season_addr).call();
    console.log("breedCount",breedCount);
    console.log("maxBreedCount",maxBreedCount);
    console.log("breedCount == maxBreedCount",breedCount == maxBreedCount);
    if(breedCount == maxBreedCount){
      let flag =  await apeContract.methods.isElder(ape_id,season_addr).call();
      console.log("xxflag",flag);
      return !flag;
    }else{
      return false;
    }
  }catch(e){
    return false;
  }
}

export async function getBreedCount(ape_id,season_addr){
  const apeContract = new web3.eth.Contract(APE_BREED_ERC721, contents.APE_BREED_ADDR);
  window.apeBeed = apeContract;
  let breedCount = await apeContract.methods.mateCount(ape_id,season_addr).call();
  return breedCount
}

export async function getTimeLeftCount(ape_id){
  const apeContract = new web3.eth.Contract(APE_BREED_ERC721, contents.APE_BREED_ADDR);
  window.apeBeed = apeContract;
  let timeLeft = await apeContract.methods.timeLeftForBorn(ape_id).call();
  return timeLeft
}

export async function getApeMatePrice(parent){

  const apeContract = new web3.eth.Contract(APE_BREED_ERC721, contents.APE_BREED_ADDR);
  // let apeTokenPrice = await apeContract.methods.getTokenPrice(contents.APE_TOKEN_ERC20_ADDR).call();

  let apeTokenPrice = await apeContract.methods.getTokenPrice(parent.father.id,parent.father_addr,parent.mother.id,parent.mother_addr,contents.APE_TOKEN_ERC20_ADDR).call();
  return apeTokenPrice
}

export function breedApe(parent,user,callback){
  return async (dispatch) => {
    console.log("parent,user",parent,user);
    try{
      const apeContract = new web3.eth.Contract(APE_BREED_ERC721, contents.APE_BREED_ADDR);
     
      const father_contract = new web3.eth.Contract(APE_ERC721, parent.father_addr);
      const mother_contract = new web3.eth.Contract(APE_ERC721, parent.mother_addr);
      console.log('father_contract.method',father_contract.methods);

      const apeToken = new web3.eth.Contract(ERC20,contents.APE_TOKEN_ERC20_ADDR);

      let approveAmount = await apeToken.methods.allowance(user.eth_address,contents.APE_BREED_ADDR).call();
      let apeTokenPrice = await apeContract.methods.getTokenPrice(parent.father.id,parent.father_addr,parent.mother.id,parent.mother_addr,contents.APE_TOKEN_ERC20_ADDR).call();

      if(bigInt(apeTokenPrice) > bigInt(approveAmount)){
        window.web3Obj = web3;
        window.apeToken = apeToken;
        let price = (bigInt(2).pow(256).subtract(1)).toString();
        //  let price = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
         await apeToken.methods.approve(contents.APE_BREED_ADDR,price).send({from:user.eth_address});
      }

      window.apeBeed = apeContract;
      let aTx = await apeContract.methods.mateWithToken(parent.father.id,parent.father_addr,parent.mother.id,parent.mother_addr,contents.APE_TOKEN_ERC20_ADDR).send({from:user.eth_address});
      let finalTx;
      do {
         finalTx = await web3.eth.getTransactionReceipt(aTx.transactionHash);
      }while (!finalTx.status);
      callback("success",finalTx);
      
    }catch(e){
      callback("error",e.message);
    }
  };
}

export function updateBreedToken(tokenId){
  return async(dispatch)=>{
    console.log("updateBreedToken",tokenId)
    const apeBreedContract = new web3.eth.Contract(APE_BREED_ERC721, contents.APE_BREED_ADDR);
    let userTokenCount = await apeBreedContract.methods.totalSupply().call();
    userTokenCount = parseInt(userTokenCount);
    let tokenUri = await apeBreedContract.methods.tokenURI(tokenId).call();
    let tokenData = await fetch(tokenUri).then(response => response.json())
    console.log("tokenData",tokenData)

    console.log("BREED_APE_TOKEN_DATA_SUCCESS",{totalToken:userTokenCount,token:tokenData,tokenId:tokenId})
    return  dispatch({
      type: 'BREED_APE_TOKEN_DATA_SUCCESS',
      json:{totalToken:userTokenCount,token:tokenData,tokenId:tokenId}
    })

  };
}


export function getAllBreedTokens(user,dataPulled=null){
  return async(dispatch)=>{
    console.log("getAllBreedTokens");
    if(dataPulled == null){
      dispatch({
        type: 'BREED_APE_ALL_TOKEN_DATA_NEW_REQUEST',
        json:{}
      })
    }else{
      dispatch({
        type: 'BREED_APE_ALL_TOKEN_DATA_REQUEST',
        json:{}
      })
    }

    
    if(dataPulled ==null){
      dataPulled = 0;
    }

    const apeBreedContract = new web3.eth.Contract(APE_BREED_ERC721, contents.APE_BREED_ADDR);
    window.apeBreedContract = apeBreedContract;
    let userTokenCount = await apeBreedContract.methods.balanceOf(user.eth_address).call();
    userTokenCount = parseInt(userTokenCount);

    console.log("userTokenCount",userTokenCount);
      
    let pull_from = userTokenCount-dataPulled;
    dataPulled +=200;
    let pull_to = userTokenCount-dataPulled;

    if(pull_to <0){pull_to=0}
    
    let tokenIds =[];
    for(let i=(pull_from-1);i >=pull_to;i--){
      let tokenId = await apeBreedContract.methods.tokenOfOwnerByIndex(user.eth_address,i).call();
      tokenIds.push(tokenId);
    } 


    let tokens =[];
    for(let i=0;i<tokenIds.length;i++){
      let tokenUri = await apeBreedContract.methods.tokenURI(tokenIds[i]).call();
      try{
        let tokenData = await fetch(tokenUri).then(response => response.json())
        tokens.push(tokenData);
      }catch(e){
        console.log("tokenData-error",e);
      }
    }

    console.log("tokens",tokens);

  if(dataPulled > 20){
    return  dispatch({
      type: 'BREED_APE_ALL_TOKEN_DATA_SUCCESS',
      json:{totalToken:userTokenCount,dataPulled:dataPulled,tokens:tokens}
    })
  }else{
    return  dispatch({
      type: 'BREED_APE_ALL_TOKEN_EXTRA_DATA_SUCCESS',
      json:{totalToken:userTokenCount,dataPulled:dataPulled,tokens:tokens}
    })
  }

  }
}

//end breed



export function metamaskLogin() {
  return dispatch => {
    ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
       return metamaskLoginChk(dispatch)
    });
  };
}

export function loginReset() {
  return dispatch => { 
    dispatch({type:"LOGIN_RESET"})
  };
}



export function checkLogin() {
  return dispatch => { 
    // userLogout()(dispatch);
    if (typeof window.ethereum !== 'undefined') { 
     return setTimeout(() => { metamaskLoginChk(dispatch)}, 1000);
    }
  }
}

export function userLogout() {
  return dispatch => {
    if (typeof window.ethereum !== 'undefined') { 
      if (ethereum.isMetaMask && ethereum.selectedAddress != (null || undefined)) { 
        return  dispatch({
            type: 'LOGOUT_SUCCESS',
            json:{data:{}}
          })
      }
    }
  };
}

export function setSound(flag){
  return dispatch => {
    return  dispatch({
      type: 'SET_APP_SOUND',
      json:flag
    })
  };
}


export function getVouchersData(user,api_call=false){
  const wallet_address=user.eth_address;
  // const wallet_address="0xdFE1EB1424987d616204Cd81855838fa6134ada7";
  return async (dispatch) => {

    return  dispatch({
      type: 'VOUCHERS_CREDITS',
      json:{}
    })
  };
}


export function mintApe(ape,type,eth_address,callback){
  return async (dispatch) => {
    let options={
      contractAddress:contents.APE_TOKEN_ADDR,
      amount:1,
      to:eth_address,
      name:ape.name,
      description:ape.description,
      metadata:ape,
      previewImage:ape.image,
      method:"mint"
    }
    cargo.api.mint(options).then(txHash => {
        callback("txHash",txHash);
      });
  };
}


export function mintApeWithToken(ape,contract_address,token_address,user,isAlpha,callback){
  console.log("mintApeWithToken",ape);
  return async (dispatch) => {
    try{ 
      const tokenContract = new web3.eth.Contract(ERC20, token_address);
      const apeContract = new web3.eth.Contract(APE_ERC721, contract_address);
      window.web3Obj = web3;
      console.log("ape",ape);
      let tokenPrice = await apeContract.methods.tokenPrice(token_address).call();
       
      let allow_pgu = await tokenContract.methods.allowance(user.eth_address,contract_address).call();
      console.log("allow_pgu",allow_pgu);
        if(allow_pgu < Number(tokenPrice)){ 
          // for making unlimited approval.
          // max 256 allow
          let maxAllow = (bigInt(2).pow(256).subtract(1)).toString();
          let aTx = await tokenContract.methods.approve(contract_address,maxAllow).send({from:user.eth_address})
        

          // let aTx = await tokenContract.methods.approve(contract_address,pguPrice).send({from:user.eth_address})
          let finalATx = await web3.eth.getTransactionReceipt(aTx.transactionHash)
        }

        setTimeout(async()=>{
          try{ 
          let tx;
          tx = await apeContract.methods.mintWithToken(token_address,ape.id).send({from:user.eth_address});
          let finalTx;
          do {
             finalTx = await web3.eth.getTransactionReceipt(tx.transactionHash);
          }while (!finalTx.status);
          callback("success",isAlpha,finalTx);
        }catch(e){
          callback("error",isAlpha,e.message);
        }
        },2000);
 
    }catch(e){
      callback("error",isAlpha,e.message);
    }
  };
}

export function mintApeWithEth(ape,contract_address,user,isAlpha,callback){
  return async (dispatch) => {
    try{
      const apeContract = new web3.eth.Contract(APE_ERC721, contract_address);
      let ethPrice;
       ethPrice = await apeContract.methods.ethPrice().call();
      let aTx;
        aTx = await apeContract.methods.mintWithEth(ape.id).send({from:user.eth_address,value:ethPrice});
      let finalTx;
      do {
         finalTx = await web3.eth.getTransactionReceipt(aTx.transactionHash);
      }while (!finalTx.status);
      callback("success",isAlpha,finalTx);
      
    }catch(e){
      callback("error",isAlpha,e.message);
    }
  };
}

export function getApePrice(){
  return async(dispatch)=>{
    const apeContract = new web3.eth.Contract(APE_ERC721, contents.APE_TOKEN_ADDR);
    window.apeContract = apeContract;
    const apeInTokenPrice = await apeContract.methods.tokenPrice(contents.APE_TOKEN_ERC20_ADDR).call();
    const ethPrice = await apeContract.methods.ethPrice().call();
    return  dispatch({
      type: 'APE_PRICE_SUCCESS',
      json:{apeInTokenPrice,eth:ethPrice}
    })
  }
}

export function chkTokenId(tokenId,cal_all,callback){
  return async(dispatch)=>{
    
    let apeData={} 
    apeData['key'] = contents.UNIQUE_KEY_SEC;
    apeData['id'] = tokenId;
    let tokenUri = "";

    const apeContract = new web3.eth.Contract(APE_ERC721, contents.APE_TOKEN_ADDR);
    window.apeContract = apeContract;
    try{
      tokenUri = await apeContract.methods.tokenURI(tokenId).call();
      apeData['is_alpha'] = false;
      apeData['status'] = true;
    }catch(e){
      console.log("tokenerror",tokenId,e);
      apeData['is_alpha'] = false;
      apeData['status'] = false;
    } 
    
     
    if(!cal_all && apeData['status'] == true){
      callback({tokenId:tokenId,tokenUri:tokenUri,status:apeData['status'],api_call:false})
      return
    }

    return postReq('/api/v1/apes/validate_ape', apeData, 'POST_VALIDATE_APE')((data) => { 
        if (data.type == 'POST_VALIDATE_APE_SUCCESS') {
          if (data.json.type == 'success') {
            callback({tokenId:tokenId,tokenUri:tokenUri,status:apeData['status'],api_call:true,type:"success"})
          }else{
            callback({tokenId:tokenId,tokenUri:tokenUri,status:apeData['status'],api_call:true,type:"success"})
          } 
        }
      })
  }

}

export function getApeNFTByUser(user,address,flag =false){
  return async(dispatch)=>{
    if(flag){
      dispatch({
        type: 'APE_NFT_NEW_DATA_REQUEST',
        json:{contract_address:address}
      })
    }else{
      dispatch({
        type: 'APE_NFT_DATA_REQUEST',
        json:{contract_address:address}
      })
    }
    
    let apeContract;
      apeContract = new web3.eth.Contract(APE_ERC721, address);
     
    const userTokenCount = await apeContract.methods.balanceOf(user.eth_address).call();
    let tokenIds =[];
    for(let i=0;i<userTokenCount;i++){
      let tokenId = await apeContract.methods.tokenOfOwnerByIndex(user.eth_address,i).call();
      tokenIds.push(tokenId);
    } 
    tokenIds.sort();
    tokenIds.reverse();
    let tokens =[];
    for(let i=0;i<tokenIds.length;i++){
      let tokenUri = await apeContract.methods.tokenURI(tokenIds[i]).call();
      try{
        let tokenData = await fetch(tokenUri).then(response => response.json())
  
        tokenData.is_alpha = false;
        tokens.push(tokenData);
      }catch(e){
        console.log("tokenUri",tokenUri);
        console.log("e",e);
      }
    }

    return  dispatch({
      type: 'APE_NFT_DATA_SUCCESS',
      json:{contract_address:address,totalToken:userTokenCount,tokens:tokens}
    })

  }
}

export function getApeNFTS(address,dataPulled=null){
  return async(dispatch)=>{
    if(dataPulled == null){
      dispatch({
        type: 'APE_NFT_TOKEN_DATA_NEW_REQUEST',
        json:{contract_address:address}
      })
    }else{
      dispatch({
        type: 'APE_NFT_TOKEN_DATA_REQUEST',
        json:{contract_address:address}
      })
    }

    
    if(dataPulled ==null){
      dataPulled = 0;
    }

    const apeContract = new web3.eth.Contract(APE_ERC721, address);
    let userTokenCount = await apeContract.methods.totalSupply().call();
    userTokenCount = parseInt(userTokenCount);
      
    let pull_from = userTokenCount-dataPulled;
    dataPulled +=20;
    let pull_to = userTokenCount-dataPulled;

    if(pull_to <0){pull_to=0}
    
    let tokenIds =[];
    for(let i=(pull_from-1);i >=pull_to;i--){
      let tokenId = await apeContract.methods.tokenByIndex(i).call();
      tokenIds.push(tokenId);
    } 


    let tokens =[];
    for(let i=0;i<tokenIds.length;i++){
      let tokenUri = await apeContract.methods.tokenURI(tokenIds[i]).call();
      try{
        let tokenData = await fetch(tokenUri).then(response => response.json());
        // if(address == contents.APE_BREED_ADDR){
        //   tokenData.is_alpha = false;
        // }else{
        //   tokenData.is_alpha = await apeContract.methods.isAlpha(tokenIds[i]).call();
        // }
        tokenData.is_alpha = false;
        tokens.push(tokenData);
      }catch(e){

      }
    }

  if(dataPulled > 20){
    return  dispatch({
      type: 'APE_NFT_TOKEN_DATA_SUCCESS',
      json:{contract_address:address,totalToken:userTokenCount,dataPulled:dataPulled,tokens:tokens}
    })
  }else{
    return  dispatch({
      type: 'APE_NFT_TOKEN_EXTRA_DATA_SUCCESS',
      json:{contract_address:address,totalToken:userTokenCount,dataPulled:dataPulled,tokens:tokens}
    })
  }

  }
}


export function getAllTokens(dataPulled=null){
  return async(dispatch)=>{
    if(dataPulled == null){
      dispatch({
        type: 'APE_ALL_TOKEN_DATA_NEW_REQUEST',
        json:{}
      })
    }else{
      dispatch({
        type: 'APE_ALL_TOKEN_DATA_REQUEST',
        json:{}
      })
    }

    
    if(dataPulled ==null){
      dataPulled = 0;
    }

    const apeContract = new web3.eth.Contract(APE_ERC721, contents.APE_TOKEN_ADDR);
    let userTokenCount = await apeContract.methods.totalSupply().call();
    userTokenCount = parseInt(userTokenCount);
      
    let pull_from = userTokenCount-dataPulled;
    dataPulled +=20;
    let pull_to = userTokenCount-dataPulled;

    if(pull_to <0){pull_to=0}
    
    let tokenIds =[];
    for(let i=(pull_from-1);i >=pull_to;i--){
      let tokenId = await apeContract.methods.tokenByIndex(i).call();
      tokenIds.push(tokenId);
    } 


    let tokens =[];
    for(let i=0;i<tokenIds.length;i++){
      let tokenUri = await apeContract.methods.tokenURI(tokenIds[i]).call();
      try{
        let tokenData = await fetch(tokenUri).then(response => response.json())
        // tokenData.is_alpha = await apeContract.methods.isAlpha(tokenIds[i]).call();
        tokenData.is_alpha = false;
        tokens.push(tokenData);
      }catch(e){

      }
    }

  if(dataPulled > 20){
    return  dispatch({
      type: 'APE_ALL_TOKEN_DATA_SUCCESS',
      json:{totalToken:userTokenCount,dataPulled:dataPulled,tokens:tokens}
    })
  }else{
    return  dispatch({
      type: 'APE_ALL_TOKEN_EXTRA_DATA_SUCCESS',
      json:{totalToken:userTokenCount,dataPulled:dataPulled,tokens:tokens}
    })
  }

  }
}



export function getTokensByUser(user){
  return async(dispatch)=>{
    dispatch({
      type: 'APE_TOKEN_DATA_REQUEST',
      json:{}
    })

    const apeContract = new web3.eth.Contract(APE_ERC721, contents.APE_TOKEN_ADDR);
    const userTokenCount = await apeContract.methods.balanceOf(user.eth_address).call();
  
    let tokenIds =[];
    for(let i=0;i<userTokenCount;i++){
      let tokenId = await apeContract.methods.tokenOfOwnerByIndex(user.eth_address,i).call();
      tokenIds.push(tokenId);
    } 
    tokenIds.sort();
    tokenIds.reverse();
    let tokens =[];
    for(let i=0;i<tokenIds.length;i++){
      // let tokenId = await apeContract.methods.tokenOfOwnerByIndex(user.eth_address,i).call();
      let tokenUri = await apeContract.methods.tokenURI(tokenIds[i]).call();
      try{
        let tokenData = await fetch(tokenUri).then(response => response.json())
        // tokenData.is_alpha = await apeContract.methods.isAlpha(tokenIds[i]).call();
        tokenData.is_alpha = false;
        tokens.push(tokenData);
      }catch(e){

      }
    }

    return  dispatch({
      type: 'APE_TOKEN_DATA_SUCCESS',
      json:{totalToken:userTokenCount,tokens:tokens}
    })

  }
}




export function resetBuildApe(){
  return (dispatch)=>{
    return  dispatch({
      type: 'BUILD_USER_APES_RESET',
      json:{}
    })
  }
}



// get migration ape
export function getValidAddress(address){
  const web3 = new Web3();
  return  web3.utils.toChecksumAddress(address);
}

export function getApeForMigration(season,address,contractAddress,callback) {
  address = getValidAddress(address);
	return fetchReq('/api/v1/apes/eth-my/'+season+'/'+address,'GET_APE_ETH_DETAILS')(async(data)=>{
     
      if(data.type == "GET_APE_ETH_DETAILS_SUCCESS"){
        if(data.json.type =='success'){
          const apeContract = new web3.eth.Contract(APE_ERC721, contractAddress);
          let mintedTokens =[];
          let migrationToken =[];
          for(let i=0;i<data.json.data.length;i++){
            try{
             let address = await apeContract.methods.ownerOf(data.json.data[i].id).call();
             mintedTokens.push(data.json.data[i]);
            }catch(e){
              migrationToken.push(data.json.data[i])
            }
          } 
          callback({type:"success",season:season,mintedTokens:mintedTokens,migrationToken:migrationToken});
        }else{
          callback({type:"error",message:"some error!"});
        }
        
      }
     
  });
};


export function mintSignature(apeData,user,callback) {
  console.log("mintSignature",apeData,callback)
  postReq('/api/v1/apes/eth/get_signature', apeData, 'APE_MIGRATION')(async(data) => { 
    if (data.type == 'APE_MIGRATION_SUCCESS' && data.json.type=='success') {
        console.log("data-mintSignature",data.json)
        try{
        const apeContract = new web3.eth.Contract(APE_ETH, apeData.contractAddr);
        let aTx = await apeContract.methods.mintEthTokenIds(data.json.hashObj,data.json.signature).send({from:user.eth_address});
        let finalTx;
        do {
          finalTx = await web3.eth.getTransactionReceipt(aTx.transactionHash);
        }while (!finalTx.status);
        callback("success",finalTx);
      }catch(e){
        callback("error",{message:e.message});
      }
    
    }
  });

}