import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { buildApe, getTotalSupply } from '../../actions';

import * as constants from "../../constants"

import StatsPanel from "../../components/StatsPanel"
import RightSideBar from "../../components/RightSideBar"
import ApeBody from "../../components/ApeBody"

const AppMaker = (props) => {
  const { user, buildApeObject } = props;

  const [currentApeContract, setCurrentApeContract] = useState(constants.APE_TOKEN_SEASON1_ADDR);

  let final_ape = buildApeObject.data;

  useEffect(() => {
    if (user.user) {
      props.getTotalSupply();

      constants.GPageView("Ape Builder", "/app_maker");
      constants.GScreenView("Ape Builder Screen");
    }
  }, [])

  const build = () => {
    if (user.user.tot_credits >= constants.IMG_GENERATE_CREDIT) {
      if (buildApeObject.type != "request") {
        props.buildApe({ eth_address: user.user.eth_address, pgfk_balance: props.PGFKBalance, contract_address: currentApeContract })
        constants.GPageEvent("Generate Ape Clicked", "Ape Generate", "Generate");
      }

    } else {
      constants.GPageEvent("Buy Credits", "Buy More Credit", "BuyCedit");
      props.history.push('/buy_credits');
    }
  }


  return (
    <>
      <div className="container mt-4">
        {(!buildApeObject.data && constants.ETH_ENV == 'developmentxx') &&
          <p>
            <button className="btn btn-danger" onClick={() => { setCurrentApeContract(constants.APE_TOKEN_SEASON2_ADDR == currentApeContract ? constants.APE_TOKEN_SEASON1_ADDR : constants.APE_TOKEN_SEASON2_ADDR) }}> Change To {constants.APE_TOKEN_SEASON2_ADDR == currentApeContract ? "Season 1" : "Season 2"}</button>
          </p>
        }


        {buildApeObject.data &&
          <StatsPanel ape={buildApeObject.data} rarity={false} />
        }

        <div className="row justify-content-center">
          <div className="col-md-6">
            <ApeBody ape={final_ape} showOverlay={true} currentApeContract={currentApeContract}/>
          </div>
        </div>

        
        <RightSideBar buildApeObject={buildApeObject} ape={buildApeObject.data} build={build} disabled={user.user.tot_credits < constants.IMG_GENERATE_CREDIT} APESupply={props.APESupply} currentApeContract={currentApeContract} />


      </div>

      <div className="clearfix mt-5-5"></div>
      <div className="row justify-content-center">
        <div className="col-md-4 text-center">
          {(buildApeObject.data && buildApeObject.data.image == "/assets/ape_img/ape_placeholder.gif") &&
            <p className="text-font-primary mt-4"><img src="/assets/images/info-warning-circle.png" className="img-fluid" /> Crew mates may take some time to fully appear. Please be patient or proceed to purchase if you wish. Once your crew mate is fully generated, they will appear in your gallery.  </p>
          }
          {/* {buildApeObject.data ?
          <>    {(final_ape && final_ape.image !="/assets/ape_img/ape_placeholder.gif") ? 
          <button className="btn-generate generate_ape_btn border-0" onClick={build} disabled={user.user.tot_credits < constants.IMG_GENERATE_CREDIT}>Make Another?</button>
          :<><br/><br/><br/></>}</>
          :
          <button className="btn-generate generate_ape_btn border-0" style={{fontSize:'32px'}} onClick={build} disabled={user.user.tot_credits < constants.IMG_GENERATE_CREDIT}>Generate</button>
          } */}

          {user.user.tot_credits >= constants.IMG_GENERATE_CREDIT ?
            null
            :
            <p className="text-font-primary mt-4"><img src="/assets/images/red.png" className="img-fluid" /> Not Enough  {constants.IMG_GENERATE_CREDIT > 1 ? "Credits" : "Credit"}!</p>
          }

          <span>

          {/* <button className="btn-generate_blink generate_ape_btn border-0 px-5 py-1" style={{fontSize:'32px'}}>Generate</button> */}
          </span>
        
        </div>


        {/* <div className="col-md-4">

        {buildApeObject.data &&
            <div className="card welcome-alert">
              <div className="card-header">
                <div className="row">
                  <div className="col-10 text_section"><h2 className="pt-1">Like How This Ape Looks?</h2></div>            
            
                  <div className="col-2 img_section buy_sec">
                  <span className="info_info" ><i className="fas fa-info"></i></span>
                    <img src="/assets/images/character-avatar.png" width="100"/> 
                    <p className="avatar-p-text ms-3">Capt Poly</p>
                  </div>
                </div>                    
              </div>
              <div className="card-body">
                <p className="px-3 pb-2">You can buy this ape and make it a pernament member of your crew.
                </p>
              </div>
              {final_ape && final_ape.image =="/assets/ape_img/ape_placeholder.gif" ?
                <div className="buy_btn"><h6 className="text-white">Generating...</h6></div>
                :
                <Link  to={{
                  pathname: `/ape_buy`, 
                  query:{ape:final_ape}
                }} className="buy_btn">Buy Now!</Link>
              }
              
            </div>
         }
          </div> */}

      </div>

    </>
  );
};

// @TODO future update review
const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
  buildApeObject: (state) => state.buildApe,
  PGFKBalance: (state) => state.PGFKBalance,
  apes: (state) => state.apes,
  APESupply: (state) => state.APESupply,
});
const mapDisactcProps = { buildApe, getTotalSupply };
export default connect(structuredSelector, mapDisactcProps)(AppMaker);
