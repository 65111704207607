import React from "react";
import SocialGemButton from "../../SocialGemButton"

const step3 = (props) => {
  return (
    <>
        <div className="mt-3 ape-main-new py-4">
          <img src="/assets/images/character.gif" className="img-fluid" />
        </div>
      </>
  );
};

export default step3;
