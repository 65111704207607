import React from "react";


const BodyPart =(props)=> {

  var stars = [];
  for (var i = 0; i < props.star; i++) {
      // note: we are adding a key prop here to allow react to uniquely identify each
      // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
      stars.push(<img
        src="/assets/images/prize-star-icon.png"
        className="img-fluid"
        key={i}
        width="25"
      />);
  }

  return (
    <div className="subcircle-parts">
      <label className="circle_title">{props.title}</label>
      <br />
      <div style={{ marginTop: "-5px" }}>
        {stars}
      </div>
      <label className="circle_lable">{props.part}</label>
    </div>
  );
};

const ApePart = (props) => {
  const {ape} = props;

  let part_name = (name) =>{
    switch(name){
      case 'chadium12': return 'Chadium12';
      default: return name;
    }
  }


  let getAttrVal = (key)=>{
    let obj = ape.attributes.find((v)=>v.trait_type == key)
    if(obj){
      // return part_name(obj.value)
      return obj.value;
    }else{
      return "N/A"
    }
  }

  let countStar = (key)=>{
    let value = getAttrVal(key);

    let obj = ape.attributes.filter((v)=>v.value == value)
    return obj.length;
  }



  return (
    <div className="body_parts">
      <div
        className="base-subcircle"
        data-circle-index="0"
        style={{
          transform: "translate3d(181.019px, -181.019px, 0px)",
        }}
      >
        <BodyPart title="LEFT ARM" part={getAttrVal('Left Arm')} star={countStar('Left Arm')}/>
      </div>
      <div
        className="base-subcircle"
        data-circle-index="1"
        style={{ transform: "translate3d(0px, -256px, 0px)" }}
      >
       <BodyPart title="HEAD" part={getAttrVal('Head')} star={countStar('Head')}/>
      </div>
      <div
        className="base-subcircle"
        data-circle-index="2"
        style={{
          transform: "translate3d(-181.019px, -181.019px, 0px)",
        }}
      >
        <BodyPart title="RIGHT ARM" part={getAttrVal('Right Arm')}  star={countStar('Right Arm')}/>
      </div>

      <div
        className="base-subcircle"
        data-circle-index="4"
        style={{
          transform: "translate3d(-181.019px, 181.019px, 0px)",
        }}
      >
        <BodyPart title="RIGHT LEG" part={getAttrVal('Right Leg')}  star={countStar('Right Leg')}/>
      </div>
      <div
        className="base-subcircle"
        data-circle-index="5"
        style={{ transform: "translate3d(0px, 256px, 0px)" }}
      >
        <BodyPart title="TORSO" part={getAttrVal('Torso')}  star={countStar('Torso')}/>
      </div>
      <div
        className="base-subcircle"
        data-circle-index="6"
        style={{
          transform: "translate3d(181.019px, 181.019px, 0px)",
        }}
      >
        <BodyPart title="LEFT LEG" part={getAttrVal('Left Leg')} star={countStar('Left Leg')}/>
      </div>
    </div>
  );
};

export default ApePart;
