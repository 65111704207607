import React from "react";
import NavBar from "../components/NavBar"

const LayoutOne = ({ children }) => <div className="on-board">{children}</div>;

const LayoutTwo = (props) => (
  <div className="landing">
    <div className="container">
      <NavBar {...props} />
      {props.children}
    </div>
  </div>
);

const LayoutThree = (props) => (
  <div className="apemaker apemaker_new">
    <div className="container">
      <NavBar {...props}/>
      {props.children}
    </div>
  </div>
);

const LayoutApeMigrate = (props) => (
  <div className="migration_landing">
    <div className="container">
      <NavBar {...props}/>
      {props.children}
    </div>
  </div>
);

export { LayoutOne, LayoutTwo, LayoutThree, LayoutApeMigrate };
