import React,{useState} from "react"
import {Link} from 'react-router-dom';
import * as constants from "../../constants"
import {baseImageApe} from "../../utlis";

import ApeMeter from "../StatsPanel/ApeMeter"

const RightSideBar = (props) =>{
  const {ape,buildApeObject,APESupply,currentApeContract} = props;
  let myRef = React.createRef();


  let countStar = (value)=>{
      if(ape){ 
        let obj = ape.attributes.filter((v)=>v.value == value)
        return obj.length;
      }else{
        return 0;
      }
    }
  
  let starsCount = [countStar('Aurora'),countStar('Frost'),countStar('Missinglink'),countStar('Snowman'),countStar('Tundra'),countStar('Yeti')].sort().reverse()[0]  


  return(
    <div className="right_sidebar" ref={myRef}>
      {/* <div className="d-flex align-items-center">
          <div className="wooden_cloud_button text-center" style={{cursor:"pointer"}}>
            <Link to="/migrate-eth-apes">
              <div className="text-center font-22" style={{marginTop:"-2px", lineHeight: '1.25'}}>Migrate Your ETH Apes</div>
            </Link>
          </div>
        </div> */}
      <ApeMeter value={starsCount} meter_position="right" />

      {(APESupply.remaing == null || APESupply.remaing > 0) ?
      <>
      {ape ?
      <>
        <div className="clearfix" />
        <br/>
        <br/>
        {(ape) &&
        <Link  to={{
                  pathname: `/ape_buy`, 
                  query:{ape:ape,currentApeContract:currentApeContract}
                }} className="btn-gem-premium border-0 mt-3">Buy Now</Link>
        }
        <div className="clearfix" />
        
        {(buildApeObject.type!='request' &&(ape && ape.image !=baseImageApe())) ?
      <>
          <p className="text-font-primary mt-5 mb-0" style={{fontSize: '1.5rem', marginLeft: '7.09rem'}}>
          Make another?</p>
          <div className="generate_green_button" style={{cursor:"pointer", marginLeft: '4.75rem'}} onClick={props.build}>
            <div className="text-center font-18" style={{marginTop:"-4px", lineHeight: '1.25'}}>Generate</div>
            <div className="count_box" style={{fontSize: '14px'}}>{constants.IMG_GENERATE_CREDIT} {constants.IMG_GENERATE_CREDIT > 1 ? "Credits" : "Credit"}</div>
          </div>
          </>
          :
          <>
          <br/>
          <br/>
          <div className="normal_button" style={{cursor:"pointer", marginLeft: '4.75rem'}} >
            <div className="text-center font-18" style={{marginTop:"-4px", lineHeight: '1.25'}}>Generating...</div>
          </div>
          </>
          }
      </>
      :
      <>
      <br/>
        <br/>
        {(buildApeObject.type!='request')? 
          <div className="generate_green_button" style={{cursor:"pointer", marginLeft: '4.75rem'}} onClick={props.build}>
            <div className="text-center font-18" style={{marginTop:"-4px", lineHeight: '1.25'}}>Generate</div>
            <div className="count_box" style={{fontSize: '14px'}}>{constants.IMG_GENERATE_CREDIT} {constants.IMG_GENERATE_CREDIT > 1 ? "Credits" : "Credit"}</div>
          </div>
       :
       <div className="normal_button" style={{cursor:"pointer", marginLeft: '4.75rem'}} >
          <div className="text-center font-18" style={{marginTop:"-4px", lineHeight: '1.25'}}>Generating...</div>
        </div>
      }
      </>
      }
      </>
     :
     <>
      <div className="clearfix" />
      <p className="text-font-primary mt-5 mb-0 text-center" style={{fontSize: '1.5rem', marginLeft: '5.09rem',width:"200px"}}>
      Brrrr. All the apes have found a crew for the winter. Come back later for the next round of crew building!</p>
     </>
     
     } 


    </div>
  )
}


export default RightSideBar
