import {ETH_ENV,DOMAIN_NAME} from '../constants'

export function ValidateEmail(email) 
{
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


export function etherUrl(url) {
    if (ETH_ENV == 'development') {
        return "https://testnet.snowtrace.io"+url; 
    } else {
       return "https://snowtrace.io"+url;  
    }
}

export function openseaUrl(url) {
    if (ETH_ENV == 'development') {
        return "https://testnets.opensea.io"+url; 
    } else {
       return "https://opensea.io"+url;  
    }
}


export function ethNetworkSubDomain() {
    if (ETH_ENV == 'development') {
        return "rinkeby"; 
    } else {
       return "";  
    }
}

export function baseImageApe(){
    return DOMAIN_NAME+"assets/ape_placeholder.gif";
}
