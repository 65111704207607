import React, { useState } from "react";


import IntroBlock from "../../components/IntroBlock"
import Step1 from "../../components/Play/Steps/step1";
import Step2 from "../../components/Play/Steps/step2";
import Step3 from "../../components/Play/Steps/step3";
import Step4 from "../../components/Play/Steps/step4";
import Step5 from "../../components/Play/Steps/step5";


const HowToPlay = (props) => {



  const [step, setStep] = useState(0);

  const slides = [
    { title: "Welcome Traveler", subTitle: "Your Guide To Ape Island!", details: "Ahoy primatey and welcome to Ape Island: Avalanche Expansion Season 1! I'm Captain Poly and I'll be your guide through the icy tundra. Ape Island is the most thrilling ape experience in all the lands. Using the Ape Builder here on the island, you can discover rare and unique apes to be added to your crew. Your crew is truly your own and you are free to trade them or expand your collection for future plunders. Now let's head over to Ape Builder and get you your first mate (or second if you've built with us before)!", step: "0", stepView: <Step1 />, paddingLeft: "25px" },
    { title: "How To Play?", subTitle: "Credits Explained", details: "This, landlubber, is the Ape Builder! This is where you can discover new recruits for your crew. To do that you're going to need credits which you can acquire by visiting your Ape Builder every 24 hours for 100 new credits. If you run out, come back the next day for more! Once you have credits, you can begin looking for new recruits.", step: "1", stepView: <Step2 />, paddingLeft: "60px" },
    { title: "Secure Your Crew", subTitle: "Minting Explained", details: "Thar she blows! Once you discover your first recruit, you have the option to mint the ape and add him/her to your crew. Minting can't be done with your credits; you will have to use APEIN.<br/><br/>Hint: When you use APEIN, you will need a little AVAX to pay for gas on the Avalanche Network.", step: "2", stepView: <Step3 />, paddingLeft: "25px" },
    { title: "You Did It!", subTitle: "Time For You To Ape In", details: "Congrats, you did it! Now that you've got your sea legs, I'll leave you to your business. If you ever need help come find me on Ape Island: Avalanche Expansion.", step: "3", stepView: <Step5 />, paddingLeft: "60px" },
  ];

  const currentSlide = slides[step];

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <IntroBlock paddingLeft={currentSlide.paddingLeft} title={currentSlide.title} subTitle={currentSlide.subTitle} details={currentSlide.details}
            currentStep={step} maxStep={4} changeStep={setStep} />
        </div>

        <div className="col-md-6 text-center mt-5">
          {currentSlide.stepView}
        </div>
      </div>
    </>
  );
};

export default HowToPlay;
