import React,{useState} from "react";


import BuyCredit from "../../components/BuyCredit"
import Step2 from "../../components/Play/Steps/step2";


const HowToPlay = (props) => {

  const currentSlide =
    {title:"Uh-oh, you’re <br/> out of credits!",subTitle:"",details:"Come back in 24 hours and we will have a fresh batch of credits for you and your crew. "};



  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <BuyCredit  title={currentSlide.title} subTitle={currentSlide.subTitle} details={currentSlide.details} />
        </div>

        <div className="col-md-6 text-center mt-5">
           <Step2 />
        </div>
      </div>
    </>
  );
};

export default HowToPlay;
